<template>
  <div class="Create">
    <h1 class="text-h5 grey--text text-uppercase">
      Criação de Campanha
    </h1>
    <v-container>
      <v-row>
        <v-col>
          <v-card max-width="800">
            <v-card-title>
              {{ stepTitles[step] }}
            </v-card-title>

            <v-form ref="newCampForm" v-model="isValid" lazy-validation>
              <v-card-text class="step1" v-if="step == 1">
                <v-text-field
                  v-model="newCamp.name"
                  :rules="[(v) => !!v || 'Digite o nome da Campanha']"
                  label="Nome"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="newCamp.client"
                  :rules="[(v) => !!v || 'Digite o nome do Cliente']"
                  label="Cliente"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="newCamp.product"
                  :rules="[(v) => !!v || 'Digite o nome do Produto']"
                  label="Produto"
                  required
                ></v-text-field>

                <!-- <v-text-field
                  v-model="newCamp.piece"
                  :rules="[(v) => !!v || 'Digite o nome da Peça']"
                  label="Peça"
                  required
                ></v-text-field> -->

                <v-text-field
                  v-model="newCamp.type"
                  :rules="[(v) => !!v || 'Digite o tipo da Peça']"
                  label="Peça - Tipo"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="newCamp.format"
                  :rules="[(v) => !!v || 'Digite o formato da Peça']"
                  label="Peça - Formato"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="newCamp.timeFrame"
                  :rules="[(v) => !!v || 'Digite o período de Veiculação']"
                  label="Veiculação"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="newCamp.piCamp"
                  label="Pi Campanha"
                ></v-text-field>
              </v-card-text>

              <v-card-text class="step2" v-if="step == 2">
                <v-file-input
                  v-model="inputFile"
                  :rules="[
                    (v) => !!v || 'Selecione uma planilha para importação!',
                    (v) =>
                      !v ||
                      v.size < 2000000 ||
                      'A planilha precisa ter menos de 2 MB!',
                  ]"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                  placeholder="Selecione uma planilha"
                  prepend-icon="mdi-microsoft-excel"
                  label="Importar Planilha"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                  show-size
                  @change="changeFileInput()"
                ></v-file-input>

                <!-- <v-btn
                  small
                  class="ml-8"
                  v-if="inputFile"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                  @click="importRadios()"
                >
                  <v-icon left dark>
                    mdi-import
                  </v-icon>
                  Importar
                </v-btn> -->
              </v-card-text>

              <v-card-text class="step3" v-if="step == 3">
                <v-file-input
                  v-model="inputTxtFile"
                  :rules="[
                    (v) => !!v || 'Selecione um arquivo de texto!',
                    (v) =>
                      !v ||
                      v.size < 2000000 ||
                      'O arquivo precisa ter menos de 2 MB!',
                  ]"
                  accept="text/plain"
                  placeholder="Selecione um arquivo de texto"
                  prepend-icon="mdi-text-box-outline"
                  label="Importar Arquivo tipo Texto"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                  show-size
                  @change="changeFileTxtInput()"
                ></v-file-input>
                <!-- <object
                  ref="textObj"
                  width="100%"
                  type="text/plain"
                  data=""
                  border="0"
                  style="overflow: hidden;"
                >
                  Not Suported Format!
                </object> -->
              </v-card-text>

              <v-card-text class="step4" v-if="step == 4">
                <v-file-input
                  v-model="inputImgFile"
                  :rules="[
                    (v) => !!v || 'Selecione uma imagem para o Banner!',
                    (v) =>
                      !v ||
                      v.size < 2000000 ||
                      'O arquivo precisa ter menos de 2 MB!',
                  ]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Selecione uma imagem"
                  prepend-icon="mdi-image"
                  label="Importar imagem para o Banner"
                  :loading="fileLoading"
                  :disabled="fileLoading"
                  show-size
                  @change="changeFileImgInput()"
                ></v-file-input>
                <p class="subtitle-1 mt-6">
                  Selecione a cor de fundo da dobra do banner
                </p>
                <v-color-picker
                  dot-size="10"
                  mode="hexa"
                  v-model="bgColor"
                ></v-color-picker>
              </v-card-text>
            </v-form>

            <v-card-actions class="py-6">
              <v-spacer></v-spacer>
              <v-btn
                class="mx-4"
                color="grey"
                text
                @click="prevStep"
                :disabled="step < 2"
              >
                Voltar
              </v-btn>

              <v-btn
                v-if="step < 4"
                class="mx-4"
                color="primary"
                @click="nextStep()"
              >
                Próximo
              </v-btn>

              <v-btn
                v-else
                class="mx-4"
                color="primary"
                @click="saveCampaign()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="">
          <v-simple-table
            v-if="radiosData.length && step == 2"
            fixed-header
            :dense="isMobile ? true : false"
            :height="
              isMobile
                ? radiosData.length > 10
                  ? 674
                  : '100%'
                : radiosData.length > 10
                ? 530
                : '100%'
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="primary darken-2 text-left white--text">
                    ID. SECOM
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    MUNICÍPIO
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    UF
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    RÁDIO
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    FX
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    PRX
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    PROGRAMA
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    APRESENTADOR
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    INÍCIO
                  </th>
                  <th class="primary darken-2 text-left white--text">
                    FIM
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(radio, i) in radiosData"
                  :key="i"
                  :class="{ grey: i % 2, 'lighten-5': i % 2 }"
                >
                  <td class="text-left">{{ radio.idSecom }}</td>
                  <td class="text-left">{{ radio.municipio }}</td>
                  <td class="text-left">{{ radio.uf }}</td>
                  <td class="text-left">{{ radio.name }}</td>
                  <td class="text-left">{{ radio.fx }}</td>
                  <td class="text-left">{{ radio.prx }}</td>
                  <td class="text-left">{{ radio.programa }}</td>
                  <td class="text-left">{{ radio.apresentador }}</td>
                  <td class="text-left">{{ radio.inicio }}</td>
                  <td class="text-left">{{ radio.fim }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="content pa-4" v-show="inputTxtFile && step == 3">
            <p>{{ newCamp.client }}</p>
            <p>{{ newCamp.name }}</p>
            <p>{{ newCamp.type }} - {{ newCamp.format }}</p>
            <p id="content"></p>
          </div>
          <div class="content pa-4" v-show="inputImgFile && step == 4">
            <v-img ref="imgPreview" max-width="800" :src="previewSrc"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { mxSlugify } from "@/mixins/SlugifyMixin";

export default {
  name: "AddCampaign",

  components: {
    //
  },

  mixins: [mxSlugify],

  mounted() {
    //
  },

  data() {
    return {
      step: 1,
      stepTitles: {
        1: "Passo 1 - Dados da Campanha",
        2: "Passo 2 - Dados das Rádios",
        3: "Passo 3 - Conteúdo Divulgado",
        4: "Passo 4 - Dados da Página",
      },
      isValid: false,
      newCamp: {},
      inputFile: null,
      fileLoading: false,
      radiosData: [],
      inputTxtFile: null,
      inputImgFile: null,
      previewSrc: null,
      bgColor: null,
    };
  },

  computed: {
    feedback() {
      return this.$store.getters.getFeedback;
    },
    user() {
      return this.$store.getters.getUser;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
  },

  methods: {
    nextStep() {
      if (this.step < 4 && this.$refs.newCampForm.validate()) {
        // console.log(this.newCamp);
        this.step++;
      }
    },
    prevStep() {
      if (this.step > 1) this.step--;
    },
    changeFileInput() {
      const file = this.inputFile;
      this.fileLoading = true;
      file
        .arrayBuffer()
        .then((res) => {
          const data = new Uint8Array(res);
          const workbook = XLSX.read(data, { type: "array" });
          const workSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(workSheet, {
            header: [
              "idRd",
              "agencia",
              "idSecom",
              "uf",
              "municipio",
              "name",
              "fx",
              "prx",
              "programa",
              "apresentador",
              "inicio",
              "fim",
            ],
            raw: true,
          });
          jsonData.shift();
          //console.log(jsonData);
          jsonData.forEach((radio) => {
            radio.inicio = this.decimalToHourMinutes(radio.inicio);
            radio.fim = this.decimalToHourMinutes(radio.fim);
            this.radiosData.push(radio);
          });
          this.fileLoading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeFileTxtInput() {
      // console.log(this.inputTxtFile);
      const reader = new FileReader();
      const content = document.querySelector("#content");
      reader.addEventListener(
        "load",
        () => {
          content.innerText = reader.result;
        },
        false
      );

      if (this.inputTxtFile) {
        reader.readAsText(this.inputTxtFile);
      }
    },
    changeFileImgInput() {
      // console.log(this.inputImgFile);
      const url = URL.createObjectURL(this.inputImgFile);
      this.previewSrc = url;
    },
    decimalToHourMinutes(decNum) {
      if (decNum != 17) {
        decNum = decNum * 24;
      } else {
        decNum = "00";
      }
      const strNum = decNum + "";
      if (strNum.indexOf(".") != -1) {
        const strPercent = strNum.substring(strNum.indexOf(".") + 1);
        const percent = strPercent / 100;
        const hours = strNum.substring(0, strNum.indexOf("."));
        let strHours = hours + "";
        if (strHours.length < 2) strHours = "0" + strHours;
        const min = Math.ceil(60 * percent);
        let strMin = min + "";
        if (strMin.length > 2) strMin = strMin.substring(0, 2);
        if (strMin.length < 2) strMin = strMin + "0";
        return `${strHours}:${strMin}`;
      } else {
        let strHours = decNum + "";
        if (strHours.length < 2) strHours = "0" + strHours;
        return `${strHours}:00`;
      }
    },
    saveCampaign() {
      if (this.currentUser) {
        const newCampaign = { ...this.newCamp };
        newCampaign.radiosData = this.radiosData;
        newCampaign.pageData = {};
        newCampaign.pageData.bgBanner = this.bgColor.hexa;
        newCampaign.ownerId = this.currentUser.id;
        newCampaign.slugId = this.mxSlugify(newCampaign.name);
        this.$store
          .dispatch("saveCampaignToFirestore", newCampaign)
          .then((res) => {
            // console.log("res", res);
            this.$store
              .dispatch("saveFilesToStorage", {
                id: res.id,
                txtFile: this.inputTxtFile,
                imgFile: this.inputImgFile,
              })
              .then(() => {
                this.$router.push({ name: "Campaigns" });
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.Create {
  padding: 20px;
}
.content {
  border: 1px solid grey;
  border-radius: 5px;
  max-width: 800px;
}
</style>
